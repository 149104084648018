import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Schedule from "components/Schedule"
import ProductCards from "components/ProductCards"

import { ITEMS } from "./constants"

import "./FGTS.styl"

const FGTS = ({ pageContext: { business } }) => {
  return (
    <Can I="management" the="fgts">
      <PageHeader title="FGTS" variant="medium" />
      <section className="fgts">
        <Schedule businessId={business.business_id} />
        <ProductCards items={ITEMS} />
      </section>
    </Can>
  )
}

export default FGTS
